import '../App.css';

export const AccountDelete = () => {
    return (
        <div className="App">
            <header className="App-header">
                <p>
                    In the AirsoftBuddy App go to the tab Profile -{'>'} Account -{'>'} Delete account and click through the pop-ups
                </p>
            </header>
        </div>
    );
}
