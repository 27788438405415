import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Suspense } from 'react';
import Home from './home/home';
import { PrivacyPolicy } from './privacy-terms/privacy-policy';
import { TermsAndCondition } from './privacy-terms/term-and-conditions';
import { AccountDelete } from './account/AccountDelete';

const App = () => {
  return (
    <Suspense fallback="loading">
      <Routes>
        <Route index element={<Home />} />
        <Route path='index.html' element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route path="/account-delete" element={<AccountDelete />} />
      </Routes>
    </Suspense>
  );
}

export default App;
